/**
 * Why helpers.ts?
 * Because sometimes we want to track same event into multiple tools and/or from multiple places.
 * You can remove helpers.ts when we will use single analytics tools (like umami for example).
 */

import { AccountType } from "@/services/user/internalUserTypes";
import * as GoogleAnalytics from "@/services/analytics/googleAnalytics";
import * as FacebookPixel from "@/services/analytics/facebookPixel";
import * as Umami from "@/services/analytics/umami";
import * as PostHog from "@/services/analytics/postHog";

export function trackNewRegistrationEvent(userId: number, accountType: AccountType): void {
  const gaEvent = new GoogleAnalytics.AccountVerifiedEvent(userId, accountType);
  GoogleAnalytics.trackEvent(gaEvent);

  const fbEvent = FacebookPixel.FacebookPixelEvent.CompleteRegistration;
  FacebookPixel.trackEvent(fbEvent);

  const umamiEvent: Umami.Event = { name: Umami.EventName.RegistrationDone };
  Umami.trackEvent(umamiEvent);
}

export function trackProfileShare(source: Umami.ShareProfileBoxSource): void {
  const umamiEvent: Umami.Event = {
    name: Umami.EventName.ShareProfileBox,
    params: { source },
  };
  Umami.trackEvent(umamiEvent);
}

export function trackIWantToTalkButtonClick(): void {
  const umamiEvent: Umami.Event = { name: Umami.EventName.MessagingIWantToTalkClick };
  Umami.trackEvent(umamiEvent);
  PostHog.trackEvent(PostHog.EventName.MessagingIWantToTalkClick);
}

export function trackArticleBannerInteraction(bannerType: Umami.ArticleBannerType, source: Umami.ArticleBannerSource): void {
  const umamiEvent: Umami.Event = { name: Umami.EventName.ArticleBanner, params: { bannerType, source } };
  Umami.trackEvent(umamiEvent);
}

/**
 * Umami only (so far)
 */
export function disallowBrowserTracking(): void {
  localStorage.setItem("umami.disabled", "1");
}
