export default {
  magazine: {
    breadcrumb: "Magazín",
    titleNoCategory: "Magazín",
    titleCategory: "{ categoryName } magazín",
    heading: "Magazín",
    shareBtn: "Sdílet",
    showMoreBtn: "Zobrazit více",
    readLength: "min čtení",
    readMore: "číst více",
    filterPlaceholder: "Kategorie",
    relatedHeading: "Mohlo by tě zajímat",
    relatedSubheading: "Magazín",
    relatedMore: "Více článků",
    widget: {
      error: "Předmět se nepodařilo načíst.",
      currency: "Kč",
      cta: "Detail předmětu",
    },
    survey: {
      loading: "Načítám anketu...",
      votesCount: "hlasů | hlas | hlasy | hlasů",
    },
  },
};
