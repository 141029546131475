export default {
  magazine: {
    breadcrumb: "Magazín",
    titleNoCategory: "Magazín",
    titleCategory: "{ categoryName } magazín",
    heading: "Magazín",
    shareBtn: "Zdieľať",
    showMoreBtn: "Zobraziť viac",
    readLength: "min čítania",
    readMore: "čítať viac",
    filterPlaceholder: "Kategória",
    relatedHeading: "Mohlo by ťa zaujímať",
    relatedSubheading: "Magazín",
    relatedMore: "Viac článkov",
    widget: {
      error: "Predmet sa nepodarilo načítať.",
      currency: "Kč",
      cta: "Detail predmetu",
    },
    survey: {
      loading: "Načítavam anketu...",
      votesCount: "hlasov | hlas | hlasy | hlasov",
    },
  },
};
