import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const handleWrongMailingUrl = async (route: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  const url = route.fullPath;
  const prefix = "/cz/collectohttps://colectio.com/cz/collectors";
  if (url.startsWith(prefix)) {
    const fixedUrl = url.replace(/^\/cz\/collectohttps:\/\/colectio\.com\//, "");
    return next(fixedUrl);
  } else {
    return next();
  }
};
