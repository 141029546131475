import { API } from "@/services/api/API";
import { addTokenToUrl, API as API2, TokenAdd } from "@/services/api2";
import { Category } from "./Category";
import { Parameter } from "./parameters/Parameter";
import { CategoryCovers, RawParameterValueData } from "@/types";
import { watch } from "vue";
import { useUserStore, store as pinia } from "@/store";
import { storeToRefs } from "pinia";

interface RawSimpleCategoryData {
  id: number;
  name: string;
}

interface RawParameterData {
  id: number;
  name: string;
  conditionParameter: number | null;
  conditionValue: number | null;
  values: RawParameterValueData[];
}

interface RawCategoryData {
  id: number;
  url: string;
  name: string;
  isSearchable: 0 | 1;
  parameters: RawParameterData[];
}

const OldApiUrl = "collection/categories/";
const NewApiUrl = import.meta.env.VITE_API_COLLECTOR_URL + "collection/category/";

// Promises cache
let getAllSortBySystemOrderPromise: Promise<Category[]> | undefined = undefined;
let getHavingArticlePromise: Promise<Category[]>; // We don't expect these will be changed very often.

// Refresh cache on language change
const userStore = useUserStore(pinia);
const { countryCode } = storeToRefs(userStore);
watch(countryCode, () => (getAllSortBySystemOrderPromise = undefined));

export class CategoryNotFoundException extends Error {}

export const Categories = {
  getHavingArticles: async function (): Promise<Category[]> {
    if (!getHavingArticlePromise) {
      getHavingArticlePromise = API.get(OldApiUrl + "get-having-articles", false).then((response) => {
        return response.categories.map((rawData: RawCategoryData) => createCategory(rawData));
      });
    }

    return getHavingArticlePromise;
  },

  getAll: async function (): Promise<Category[]> {
    if (typeof getAllSortBySystemOrderPromise === "undefined") {
      const url = OldApiUrl + "get-all-public";
      getAllSortBySystemOrderPromise = API2.getPublic<RawCategoryData[]>(url, {}, TokenAdd.Never).then((response) => createCategoriesArray(response.data));
    }

    return getAllSortBySystemOrderPromise;
  },

  getMyTopUsed: async function (): Promise<Category[]> {
    const url = NewApiUrl + "my-top-used";
    return API2.get<RawSimpleCategoryData[]>(url).then(async (response) => {
      const promises = response.data.map(async (data) => await this.getById(data.id));
      return await Promise.all(promises);
    });
  },

  getByUrl: async function (slug: string): Promise<Category> {
    const categories = await this.getAll();
    const category = categories.find((category) => category.url === slug);
    if (category) {
      return category;
    } else {
      throw new CategoryNotFoundException();
    }
  },

  getById: async function (id: number): Promise<Category> {
    return new Promise((resolve) => {
      this.getAll().then((categories) => {
        const found = categories.find((category) => category.getId() === id);
        if (found) {
          resolve(found);
        }
      });
    });
  },
};

/**
 * Factories
 */

export function createCategory(data: RawCategoryData): Category {
  const coverFile = CategoryCovers.get(data.id);
  const cover = coverFile ? "/images/categories/" + coverFile + ".jpg" : undefined;

  const category = new Category(data.id, data.url, data.name, data.isSearchable, cover);

  if (data.parameters) {
    category.parameters = data.parameters.map((paramData) => {
      return new Parameter(paramData);
    });
  }

  return category;
}

function createCategoriesArray(data: RawCategoryData[]): Category[] {
  return data.map((rawData) => createCategory(rawData));
}
