export default {
  publicItemsFilter: {
    reset: "Obnovit vše",
    onlyForSale: "Pouze k prodeji",
    categories: {
      placeholder: "Všechny kategorie",
      all: "@:components.publicItemsFilter.categories.placeholder",
      favourite: "Oblíbené kategorie",
    },
    price: {
      label: "Cena",
      from: "od",
      to: "do",
      includeNegotiablePrice: "Cena dohodou",
    },
  },
};
