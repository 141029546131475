export default {
  publicHomepage: {
    hero: {
      textLine1: "Zbieraj,&nbsp;predávaj",
      textLine2: "alebo vymieňaj",
      categories: {
        lego: "LEGO",
        watch: "hodinky",
        vinyls: "vinyly",
        art: "umenie",
        alcohol: "alkohol",
        cards: "kartičky",
      },
      cta: "Vytvor si zbierku a predávaj",
    },
    items: {
      heading: "Zberateľské predmety",
      subHeadingText: "Zbieraj, predávaj, vymieňaj. To všetko je možné v našej novovytvorenej zberateľskej sekcii.",
      cta: "Viac predmetov",
      errorMsg: "Predmety sa nepodarilo načítať.",
      errorRetryBtn: "Skús znovu",
    },
    collectors: {
      heading: "Zberatelia",
      cta: "Viac zberateľov",
    },
    magazine: {
      heading: "Magazín",
      moreBtn: "Viac článkov",
    },
  },
};
