import { BuildMode } from "@/types";

export enum EventName {
  MessagingIWantToTalkClick = "messages-funnel-click",
  MessagingConversationStarted = "messages-funnel-first-message",
  MessagingFirstReaction = "messages-funnel-first-reaction",
}

const mode = import.meta.env.MODE;

export function trackEvent(event: EventName): void {
  if (mode !== BuildMode.production) {
    return; // Track only in production
  }

  if (typeof window.posthog === "undefined") {
    throw new Error("DEV: Should not happen. PostHog not found.");
  }

  window.posthog.capture(event);
}
