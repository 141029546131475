import { hidePrices as savePricesHidden, showPrices as savePricesShown, User } from "@/services/user";
import { createAccountSetupLink, router } from "@/router";
import { computed, ref } from "vue";
import { useUserStore } from "@/store";
import { Right } from "@/services/user/internalUserTypes";

const arePricesVisible = ref<boolean>(false);

export function usePriceVisibility() {
  const { user } = useUser();
  arePricesVisible.value = user.value?.hasVisiblePrices() ?? true;

  const hidePrices = () => {
    user.value?.setShowPrices(false);
    arePricesVisible.value = false;
    savePricesHidden(); // Save settings in BE.
  };

  const showPrices = () => {
    arePricesVisible.value = true;
    user.value?.setShowPrices(true);
    savePricesShown(); // Save settings in BE.
  };

  return {
    hidePrices,
    showPrices,
    arePricesVisible,
  };
}

export function useRedirectToNicknameDialog() {
  const redirectToNicknameDialog = (): void => {
    const link = createAccountSetupLink();
    router.push(link);
  };

  return {
    redirectToNicknameDialog,
  };
}

export function useUser() {
  const userStore = useUserStore();
  const user = computed(() => userStore.get());

  const hasRight = (right: Right, user: User): boolean => user.rights.includes(right);

  return {
    user,
    hasRight,
  };
}
