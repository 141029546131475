import { BuildMode } from "@/types";
import { getEnumKeyByValue } from "@/utilities";
import * as Sentry from "@sentry/vue";
import { App } from "vue";

export const setupSentry = (app: App): void => {
  const mode = getEnumKeyByValue(import.meta.env.MODE, BuildMode);
  const useSentryModes = [BuildMode.production, BuildMode.beta];
  if (!useSentryModes.includes(mode)) {
    return;
  }

  const dsn = import.meta.env.VITE_SENTRY_DSN;
  const version = import.meta.env.VITE_APP_VERSION;

  Sentry.init({
    app,
    dsn,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
      Sentry.captureConsoleIntegration(),
    ],
    release: version,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
